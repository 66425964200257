var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.pendingOrders),function(pendingOrder){return _c('v-container',{key:pendingOrder.id,staticClass:"order mt-1 mb-3",attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-1"},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"body-2"},on),[_vm._v(" "+_vm._s(pendingOrder.liveSessionProduct.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.product-name')))])])],1),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"order-comment body-2"},on),[_vm._v(" "+_vm._s(_vm.formatComment(pendingOrder))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.comment-message')))])])],1),_c('v-col',{staticClass:"body-2",attrs:{"cols":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatDate(pendingOrder.createdDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.record-date')))])])],1),_c('v-col',{staticClass:"body-2 indigo--text darken-4",attrs:{"cols":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(pendingOrder.quantity)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.quantity')))])]),_c('span',[_vm._v("x")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatPrice(pendingOrder.price))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.price')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"red--text darken-1"},on),[_vm._v(" "+_vm._s(_vm.formatTotalAmount(pendingOrder.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])])],1)],1)],1)}),(!_vm.pendingOrders.length)?_c('v-container',{staticClass:"order-no-data mt-1 mb-3",attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:"text-center body-2"},[_vm._v(_vm._s(_vm.$t("$vuetify.noDataText")))])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }