


















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { MainProperties } from "./MainView.vue";
import { PendingOrderProperties } from "./PendingOrderAView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import CompanyService from "@/services/CompanyService";
import LiveSessionDetailService from "@/services/LiveSessionDetailService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import DateUtil from "@/utilities/DateUtil";

@Component({})
export default class PendingOrderBView extends Vue {
    @Prop() private main: MainProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private properties: PendingOrderProperties;
    private companyService = new CompanyService();
    private liveSessionDetailService = new LiveSessionDetailService();

    private dateTimeFormat: string = null;

    public get pendingOrders() {
        return this.properties.pendingOrders;
    }

    public get currency() {
        return this.properties.currency;
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatPrice(v: any) {
        const symbol = this.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

    public formatTotalAmount(v: any) {
        const symbol = this.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

	public formatComment(order: any) {
		var m = order.facebookComment?.message ?? "";
		const r = order.remarks ?? "";

		if (r !== "") {
			const t = this.$t('text.remarks');
			m += `\n${t}: ${order.remarks}`;
		}
		return m;
	}

    public created() {
        this.load();
    }

    public async loadCurrency() {
        const r = await this.companyService.currency();
        this.properties.currency = r.data;
    }

    public async loadPendingOrders() {
        const r = await this.liveSessionDetailService.current({});
        const pendingOrders: any[] = r.data.liveSessionDetails;
        this.properties.pendingOrders = pendingOrders.sort((lhs, rhs) => {
			return DateUtil.compare(rhs.createdDate, lhs.createdDate);
        });

        this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
    }

    public async load() {
        this.main.loading = true;

        try {
            await this.loadCurrency();
            await this.loadPendingOrders();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.main.loading = false;
        }
    }
}
